import { Injectable } from '@angular/core';
import { SettingsService } from 'app/_services/settings.service';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SupportGerenciarVendedoresService {
    private _apiBuyerUrl: string = `${environment.apiBuyer}`;
    private _domain: string = '/support/manage-sellers';

    constructor(private config: SettingsService) { }

    public changeRelationship(buyerCompanyId: number, supplierHoldingId: number, newSellerCompanyId: number): Observable<any> {
        const body = { newSellerCompanyId };

        return this.config.executePut({ baseUrl: this._apiBuyerUrl, url: `/my-sellers/${buyerCompanyId}/suppliers/${supplierHoldingId}/change`, body })
    }


    public getAllSellers(holdingId: number, isBillable: boolean): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/holdings/${holdingId}?isBillable=${isBillable}` });
    }

    public removeRelationship(sellerCompanyId: number, buyerCompanyId: number): Observable<any> {
        return this.config.executeDelete({ url: `${this._domain}/${sellerCompanyId}/buyers/${buyerCompanyId}` });
    }
}
